<template>
  <el-tooltip
    :open-delay="700"
    effect="dark"
    :content="$t('content.object_last_state_stay')"
    placement="bottom"
    :offset="2"
    :visible-arrow="false"
  >
    <svg
      width="39"
      height="24"
      viewBox="0 0 39 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H27C33.6274 0 39 5.37258 39 12C39 18.6274 33.6274 24 27 24H12C5.37258 24 0 18.6274 0 12Z"
        fill="#F7CA5A"
      />
      <path
        d="M12.8492 6H7.67999V18.48H10.6338V14.32H12.8492C15.2935 14.32 17.28 12.4549 17.28 10.16C17.28 7.86507 15.2935 6 12.8492 6ZM12.9969 11.5467H10.6338V8.77333H12.9969C13.8092 8.77333 14.4738 9.39733 14.4738 10.16C14.4738 10.9227 13.8092 11.5467 12.9969 11.5467Z"
        fill="white"
      />
      <path
        d="M28.25 6.5C25.903 6.5 24 8.403 24 10.75C24 11.2711 24.0988 11.7681 24.2705 12.2295L20.5 16V18.5H23V17H24.5V15.5H26L26.7705 14.7295C27.2319 14.9012 27.7289 15 28.25 15C30.597 15 32.5 13.097 32.5 10.75C32.5 8.403 30.597 6.5 28.25 6.5ZM29.25 8.5C29.9405 8.5 30.5 9.0595 30.5 9.75C30.5 10.4405 29.9405 11 29.25 11C28.5595 11 28 10.4405 28 9.75C28 9.0595 28.5595 8.5 29.25 8.5Z"
        fill="white"
      />
    </svg>
  </el-tooltip>
</template>

<script>
export default {
  name: 'ParkingIgnitionYellowIcon'
}
</script>
